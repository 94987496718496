import { Icon } from '@ailibs/feather-react-ts';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Module } from '../../types/AccessTypes';
import { useInvalidateAvailableCustomerData } from '../../providers/AvailableCustomerDataProvider';
import { IUserSetting } from '../usersettings/NotificationSettingsTab';
import { useApi } from '../../query/GenericQuery';

export const ProductInfoOptIn = () => {
  const invalidateAvailableData = useInvalidateAvailableCustomerData();

  const { data: settings } = useApi<IUserSetting[]>(
    'account/settings',
  );

  const isNotificationsEnabled = settings?.find((s) => s.key === 'notification.enable')?.value === 'true';
  const [saving, setSaving] = useState(false);

  const save = useCallback(async () => {
    setSaving(true);
    try {
      await axios.put(
        `api/v1/account/setting/${Module.none}/notification.product-info`,
        {
          value: 'true',
        },
      );

      if (!isNotificationsEnabled) {
        await axios.put(
        `api/v1/account/setting/${Module.none}/notification.enabled`,
        {
          value: 'true',
        },
        );
      }

      await invalidateAvailableData();

      toast.success('Thank you! You are now registered to receive product updates on behalf of your organization.');
    } catch {
      // Ignore
    } finally {
      setSaving(false);
    }
  }, [invalidateAvailableData, isNotificationsEnabled]);

  return (
    <div>
      <div className="mb-3">
        No administrators in your organization are configured to receive important product update emails.
      </div>

      <Button
        onClick={async () => save()}
        disabled={saving}
      >
        { saving
          ? <Spinner animation="border" size="sm" className="me-1 pb-1" />
          : <Icon name="mail" className="me-1 pb-1" size={20} /> }
        Click to receive product updates
      </Button>
    </div>
  );
};
