import React from 'react';
import { Card, Tab } from 'react-bootstrap';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { useAccount } from '../../providers/AccountProvider';
import { AdminNotificationsSendTab } from './AdminNotificationsSendTab';
import { AdminNotificationsMessageLogTab } from './AdminNotificationsMessageLogTab';
import { Module } from '../../types/AccessTypes';
import { StatefulTabs } from '../../components/StatefulTabs';

export const AdminNotificationsPage:IComponentWithLoader<null, null> = {
  loader: async () => null,
  Component: () => {
    const { hasModuleRole } = useAccount();

    return (
      <Card>
        <Card.Header>
          Notifications
        </Card.Header>
        <Card.Body>
          <StatefulTabs
            defaultActiveKey={hasModuleRole(Module.admin, 'admin') ? 'send' : 'log'}
            mountOnEnter
            className="mb-3"
          >
            { hasModuleRole(Module.admin, 'admin')
              ? (
                <Tab title="Send" eventKey="send">
                  <AdminNotificationsSendTab />
                </Tab>
              ) : null }
            <Tab title="Message log" eventKey="log">
              <AdminNotificationsMessageLogTab />
            </Tab>
          </StatefulTabs>
        </Card.Body>
      </Card>
    );
  },
};
