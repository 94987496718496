import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert, Button, Card, Col, Form, Row, Stack, Tab,
} from 'react-bootstrap';
import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAccount } from '../../providers/AccountProvider';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import { Setting } from '../../types/Types';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { IUserSetting, NotificationSettingsTab } from './NotificationSettingsTab';
import { StatefulTabs } from '../../components/StatefulTabs';

const notificationSettingPrefix = 'notification.';

interface IFormModel {
  email?:string,
  settings:Record<string, Setting>
}

export const UserSettingsPage:IComponentWithLoader<IUserSetting[], undefined> = {
  loader: (queryClient:QueryClient) => (
    getOrFetchFromApi<IUserSetting[]>(
      queryClient,
      'account/settings',
    )
  ),
  Component: () => {
    const { user } = useAccount();

    const [busy, setBusy] = useState(false);

    const { data: settings } = useApiLoaderData<IUserSetting[], IUserSetting[]>(
      'account/settings',
      (loaderData) => loaderData,
    );

    const settingsMap = useMemo(() => {
      const mSettingMap:Record<string, Setting> = {};
      settings.forEach((s) => {
        if (s.key.startsWith(notificationSettingPrefix)) {
          mSettingMap[s.key] = {
            key: s.key,
            value: s.value,
          };
        }
      });
      return mSettingMap;
    }, [settings]);

    const [userState, setUserState] = useState<IFormModel>({
      email: user.email ?? '',
      settings: settingsMap,
    });

    const [initialUserState, setInitialUserState] = useState(userState);

    const isDirty = useMemo(() => (
      JSON.stringify(userState) !== JSON.stringify(initialUserState)
    ), [userState, initialUserState]);

    useEffect(() => {
      setUserState({
        ...userState,
        settings: settingsMap,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsMap]);

    const save = async () => {
      setBusy(true);
      try {
        await axios.put(
          'api/v1/account/settings',
          Object.values(userState.settings),
        );
        await axios.put(
          'api/v1/account',
          {
            email: userState.email,
          },
        );
        setInitialUserState(userState);
      } catch (err) {
        toast.error(`Unable to save account details: ${err}`);
      } finally {
        setBusy(false);
      }
    };

    return (
      <Card>
        <Card.Body>
          <StatefulTabs
            className="mb-3"
            defaultActiveKey="details"
            mountOnEnter
          >
            <Tab eventKey="details" title="User details">
              <Row>
                <Col md={6} className="mb-3">
                  <div className="form-label">Name:</div>
                  <div className="form-control">{user.name}</div>
                </Col>
                <Col md={6} className="mb-3">
                  <div className="form-label">UPN:</div>
                  <div className="form-control">{user.externalId}</div>
                </Col>
              </Row>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await save();
                }}
              >
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="email"
                      placeholder={user.externalId}
                      type="email"
                      disabled={busy}
                      value={userState.email}
                      onChange={(e) => setUserState({
                        ...userState,
                        email: e.target.value,
                      })}
                    />
                    { !initialUserState.email
                      ? (
                        <Alert variant="info" className="mt-1 p-2">
                          As you have not provided an email address, you will receive
                          notifications at the address registered as your identity
                          provider&apos;s UPN. If the UPN is not set up for receiving
                          email, you should add a separate email address for notifications.
                        </Alert>
                      )
                      : null }
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Stack direction="horizontal" gap={2}>
                      <Button
                        disabled={busy || !isDirty}
                        onClick={async () => save()}
                      >
                        Save
                      </Button>
                      <Button
                        variant="secondary"
                        disabled={!isDirty}
                        onClick={() => setUserState(initialUserState)}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Tab>
            <Tab eventKey="notifications" title="Notifications">
              <NotificationSettingsTab />
            </Tab>
          </StatefulTabs>
        </Card.Body>
      </Card>
    );
  },
};
