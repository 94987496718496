import { ColumnFilter } from '@tanstack/react-table';
import React, { useCallback } from 'react';
// @ts-ignore
import naturalSort from 'javascript-natural-sort';
import { TableColumnDefV8 } from '../ReactTableV8';
import { NoClipDropdown } from '../../NoClipDropdown';
import { hasFilterValue } from '../TableStoreV8';
import { FilterV8Props } from './TableFilter';
import { getFilterName } from './FilterHelpersV8';

type ColumnFilterValue = ColumnFilter['value'];

export const MultiSelectReactTableFilterV8 = <T, TData, >(props: FilterV8Props<T>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    column, size, table,
  } = props;

  const columnDefV8 = column.columnDef as TableColumnDefV8<T, TData>;
  const { getCoreRowModel } = table;

  const sortedUniqueValues = React.useMemo(
    () => {
      const values = getCoreRowModel().flatRows.map((row) => row.getValue(column.id)) as string[];
      return Array.from(new Set(values)).sort(columnDefV8.optionsSortFn ?? naturalSort);
    },
    [column, columnDefV8.optionsSortFn, getCoreRowModel],
  );

  const columnFilterValue = (column.getFilterValue() ?? []) as (string|undefined)[];

  const getSanitizedValue = (value:unknown) => (typeof value === 'undefined' ? null : value);

  const handleChecked = (checked: boolean, value: ColumnFilterValue) => {
    const sanitizedValue = getSanitizedValue(value);
    if (checked) {
      if (!columnFilterValue) {
        column.setFilterValue([sanitizedValue]);
      } else {
        const newFilterValue = [...(columnFilterValue as ColumnFilterValue[]), sanitizedValue];
        column.setFilterValue(newFilterValue);
      }
    } else if (columnFilterValue) {
      const newFilterValue = (columnFilterValue as ColumnFilterValue[]).filter((v) => v !== sanitizedValue);
      column.setFilterValue(newFilterValue);
    }
  };

  const formatValue = (value:unknown) => {
    if (columnDefV8.formatter) {
      return columnDefV8.formatter(value as TData);
    }
    if (typeof value !== 'undefined' && value !== null && value !== '') {
      return String(value);
    }
    return '<empty>';
  };

  return (
    <NoClipDropdown
      className={hasFilterValue(columnFilterValue) ? 'has-selection' : undefined}
      filterLabel={getFilterName(columnFilterValue, 'All', false, formatValue)}
      itemId={(item:unknown) => (item ? String(item) : 'empty')}
      itemLabel={formatValue}
      filterId={column.id}
      checked={(i) => (
        ((columnFilterValue ?? []) as ColumnFilterValue[]).includes(getSanitizedValue(i))
      )}
      onCheck={handleChecked}
      disabled={sortedUniqueValues.length === 0}
      size={size}
      values={sortedUniqueValues}
    />
  );
};

export const SelectFilterV8 = <T, TData, >({
  columnId,
  columnDef,
  filterValues,
  options,
  size,
  setFilterValues,
  multiselect,
  disabled,
}:{
  columnId: string,
  columnDef: TableColumnDefV8<T, TData>,
  filterValues: unknown[]|null,
  options: readonly unknown[],
  className?: string,
  size?:'sm'|'lg'|undefined,
  setFilterValues:(values:unknown[]|null) => Promise<void>|void,
  multiselect?:boolean,
  disabled?:boolean
}) => {
  const getSanitizedValue = (value:unknown) : unknown|null => (value === undefined || value === null ? null : value);

  const handleChecked = async (checked: boolean, value: unknown) => {
    const sanitizedValue = getSanitizedValue(value);
    if (checked && sanitizedValue) {
      if (!filterValues) {
        await setFilterValues([sanitizedValue]);
      } else {
        const newFilterValue = multiselect ? [...filterValues, sanitizedValue] : [sanitizedValue];
        await setFilterValues(newFilterValue);
      }
    } else if (Array.isArray(filterValues)) {
      const newFilterValue = filterValues.filter((v) => v !== sanitizedValue);
      await setFilterValues(newFilterValue);
    } else {
      await setFilterValues([]);
    }
  };

  const formatValue = useCallback((value:unknown) => {
    if (columnDef.formatter) {
      return columnDef.formatter(value as TData);
    }
    if (typeof value !== 'undefined' && value !== null && value !== '') {
      return String(value);
    }
    return '<empty>';
  }, [columnDef]);

  return (
    <NoClipDropdown
      className={filterValues?.length ? 'has-selection' : undefined}
      filterLabel={getFilterName(filterValues, 'All', false, formatValue)}
      itemId={(item) => String(item)}
      itemLabel={formatValue}
      filterId={columnId}
      checked={(i) => (
        !!(i && (filterValues ?? []).includes(i))
      )}
      onCheck={handleChecked}
      disabled={options.length === 0 || disabled}
      size={size}
      values={options}
      multiselect={multiselect}
    />
  );
};
