import React from 'react';
import {
  Alert, Col, Form, Row,
} from 'react-bootstrap';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ItemsSelector from '../../common/ItemsSelector';
import { useInvalidateQueries } from '../../query/GenericQuery';
import { ICustomerSettingsCustomer } from './CustomerTypes';
import ROUTES, { Routes } from '../../routing/Routes';

interface IProps {
  customer:ICustomerSettingsCustomer
}

const CustomerNotificationTab = (props:IProps) => {
  const { customer } = props;

  const invalidate = useInvalidateQueries('module/customer');

  const saveMutation = useMutation({
    mutationFn: async (data:string[]) => axios.put(
      '/api/v1/module/customer',
      { ...customer, notificationEmails: data } as ICustomerSettingsCustomer,
    ),
    onSuccess: () => {
      invalidate();
      toast.success('Notification receivers have been updated');
    },
  });

  return (
    <Row>
      <Col sm={6} className="mb-3">
        { customer.notificationEmails?.length
          ? (
            <>
              <Form.Label>Notification emails:</Form.Label>
              <ItemsSelector
                id="customer-settings-notification-emails"
                items={customer.notificationEmails}
                inputType="email"
                removeEnabled
                addEnabled
                disabled
                addLabel="Add notification email"
                addPlaceholder="Enter notification email..."
                onChange={async (items) => { saveMutation.mutateAsync(items); }}
              />
            </>
          ) : null }
        <Alert variant="warning" className="p-2">
          <div>
            Customer notifications have been deprecated in favor of more granular user notifications.
            You&apos;ll find these in your
            {' '}
            <Link to={Routes.getRouteRelativeUri(ROUTES.usersettings, { tab: 'notifications' }, true).toString()}>
              user settings
            </Link>
            .
            { customer.notificationEmails.length
              ? (
                <p className="mt-3 mb-0">
                  Legacy notification receivers will receive open, close and status change notifications for
                  changed risks, and general product information.
                </p>
              )
              : null }
          </div>
        </Alert>
      </Col>
    </Row>
  );
};

export default CustomerNotificationTab;
