import { useTranslation } from '../providers/TranslationProvider';
import {
  SecurityLevel, Significance, VulnerabilityStatus, Severity,
} from '../pages/vulnerabilities/Types';
import { EntityType } from '../types/EntityTypes';
import { AssetType } from '../types/Types';
import { RiskStatus } from '../types/RiskTypes';
import { AccountProvider, Module, Role } from '../types/AccessTypes';
import { IAsset } from '../types/AssetsTypes';
import { ActiveStatus } from '../types/AdminTypes';

// TODO: This is growing out of hand, collect all these in a single useTranslation related hook.

export const useVulnerabilityStatusAsText = () => {
  const translation = useTranslation();
  return (status:VulnerabilityStatus|undefined) => (status ? translation.getString(`vulnerability.status.${status}`) : undefined);
};

export const useVulnerabilityStatusAsControlImplementationText = () => {
  const translation = useTranslation();
  return (status:VulnerabilityStatus|undefined) => (status ? translation.getString(`control.status.${status}`) : undefined);
};

export const useEntityTypeAsText = () => {
  const translation = useTranslation();
  return (entityType:EntityType) => translation.getString(`entity-type.${entityType}`);
};

export const useSecurityLevelAsText = () => {
  const translation = useTranslation();
  return (securityLevel:SecurityLevel, defaultValue?:SecurityLevel) => {
    const securityLevelOrDefault = securityLevel !== SecurityLevel.Unknown
      ? securityLevel
      : defaultValue ?? securityLevel;
    return translation.getString(`security-level.${securityLevelOrDefault}`);
  };
};

export const useRiskStatusAsText = () => {
  const translation = useTranslation();
  return (riskStatus:RiskStatus) => translation.getString(`risk-status.${riskStatus}`);
};

export const useAssetTypeAsText = () => {
  const translation = useTranslation();
  return (assetType:AssetType, emptyUnknowns = true) => (assetType === 'unknown' && emptyUnknowns
    ? ''
    : translation.getString(`asset-type.${assetType}`));
};

export const useGetSeverityAsText = () => {
  const translation = useTranslation();
  return (severity:Severity|undefined) => translation.getString(`significance.${severity ?? 'unknown'}`);
};

export const useGetSignificanceAsText = () => {
  const translation = useTranslation();
  return (significance:Significance) => translation.getString(`significance.${significance}`);
};

export const useGetRoleAsText = () => {
  const translation = useTranslation();
  return (role:Role|undefined) => translation.getString(`role.${role ?? 'none'}`);
};

export const useGetAccountProviderAsText = () => {
  const translation = useTranslation();
  return (provider:AccountProvider|undefined) => translation.getString(`account-provider.${provider ?? 'none'}`);
};

export const useGetUserNotificationAsText = () => {
  const translation = useTranslation();
  return (moduleId:Module, key:string) => (
    translation.getString(
      `user-notification.${moduleId}.${key}`,
      translation.getString(`user-notification.${key}`),
    )
  );
};

export const useGetAssetNameAsText = () => {
  const translation = useTranslation();
  return (asset:IAsset|undefined) => (asset?.name && asset.name !== asset.friendlyId
    ? asset.name
    : translation.getString(`asset.well-known-name.${asset?.friendlyId}`, asset?.friendlyId));
};

export const useGetActiveStatusAsText = () => {
  const translation = useTranslation();
  return (status:ActiveStatus) => translation.getString(`entity.status.${status}`);
};
