import React, {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import { Tabs } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

const activeTabGetParamName = 'tab';

export const StatefulTabs = ({
  children,
  defaultActiveKey,
  className,
  mountOnEnter,
}:{
  children:ReactNode,
  defaultActiveKey:string,
  className?:string,
  mountOnEnter?:boolean
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  useEffect(() => {
    const eventKey = searchParams.get(activeTabGetParamName);
    if (eventKey && eventKey !== defaultActiveKey) {
      setActiveKey(eventKey as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activateTab = useCallback((eventKey:string|null) => {
    if (!eventKey) {
      searchParams.delete(activeTabGetParamName);
    } else if (eventKey !== defaultActiveKey) {
      searchParams.set(activeTabGetParamName, eventKey);
    } else {
      searchParams.delete(activeTabGetParamName);
    }
    if (eventKey) {
      setActiveKey(eventKey);
    }
    setSearchParams(searchParams);
  }, [defaultActiveKey, searchParams, setSearchParams]);

  return (
    <Tabs
      className={className ?? ''}
      activeKey={activeKey}
      mountOnEnter={mountOnEnter}
      defaultActiveKey={defaultActiveKey}
      onSelect={activateTab}
    >
      {children}
    </Tabs>
  );
};
