import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Form, ListGroup, ListGroupItem, Spinner,
} from 'react-bootstrap';
import './itemSelector.css';
import { Icon } from '@ailibs/feather-react-ts';

interface IProps<T> {
  items:T[],
  id:string,
  addPlaceholder?:string,
  addLabel?:string,
  searchLabel?:string,
  inputType?:'text'|'email'|'number',
  inputPattern?:string,
  addEnabled?: boolean,
  removeEnabled?: boolean,
  disabled?: boolean,
  searchEnabled?: boolean,
  onChange?:(selectedItems:T[]) => Promise<void>|void,
  selectedItem?:T,
  onSelectItem?:(item:T) => void|Promise<void>,
  isSearching?:boolean,
  onSearchChange?:(search:string) => Promise<void>|void,
  renderer?:(item:T) => string|React.ReactNode,
  itemToString?:(item:T) => string
  toItem?:(item:string) => T
}

const ItemsSelector = <T, >(props:IProps<T>) => {
  const {
    items, id, addLabel, addPlaceholder, onChange, inputType, inputPattern,
    removeEnabled, onSelectItem, searchEnabled, searchLabel, renderer,
    itemToString: _itemToString, toItem, onSearchChange, isSearching,
    selectedItem: _selectedItem, addEnabled, disabled,
  } = props;

  const [newItem, setNewItem] = useState('');
  const [isItemValid, setIsItemValid] = useState(false);
  const [selectedItem, setSelectedItem] = useState(_selectedItem);
  const [search, setSearch] = useState('');

  const itemToString = useMemo(() => (
    (item:T) => (_itemToString ? _itemToString(item) : String(item))
  ), [_itemToString]);

  const isStrictMatch = (item:T, s:string) => (itemToString(item) === s);
  const canAdd = () => (isItemValid && !items.find((i) => isStrictMatch(i, newItem)));

  useEffect(() => {
    setSelectedItem(_selectedItem);
  }, [_selectedItem]);

  const onSubmit = async () => {
    if (!onChange) return;
    await onChange([...items, toItem ? toItem(newItem) : newItem as T]);

    setNewItem('');
    setSearch('');
    if (onSearchChange) onSearchChange('');
    setIsItemValid(false);
  };

  const removeItem = (item:T) => {
    if (!onChange) return;
    onChange(items.filter((i) => i !== item));
  };

  const inputId = `${id}-input`;

  return (
    <div className="item-selector">
      { onChange && addEnabled
        ? (
          <div className="mb-4">
            <Form.Control
              className="mb-2"
              type={inputType ?? 'text'}
              name="item"
              pattern={inputPattern}
              id={inputId}
              disabled={disabled}
              autoComplete="off"
              placeholder={addPlaceholder ?? 'Enter new item...'}
              value={newItem}
              onChange={(e) => {
                setNewItem(e.target.value);
                const inputElement = document?.getElementById(inputId) as HTMLInputElement;
                if (inputElement) setIsItemValid(inputElement.checkValidity());
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && canAdd()) {
                  e.preventDefault();
                  onSubmit();
                }
              }}
            />
            <Button
              type="button"
              disabled={!newItem || !canAdd()}
              onClick={(e) => {
                e.preventDefault();
                if (isItemValid) onSubmit();
              }}
            >
              { addLabel ?? 'Add' }
            </Button>
          </div>
        ) : undefined }
      { searchEnabled
        ? (
          <Form.Control
            className="search mb-3"
            placeholder={searchLabel ?? 'Search'}
            value={search}
            onChange={async (e) => {
              setSearch(e.target.value);
              if (onSearchChange) {
                await onSearchChange(e.target.value);
              }
            }}
          />
        )
        : undefined }
      { isSearching
        ? <Spinner animation="grow" size="sm" />
        : (
          <ListGroup>
            { items.map((item) => {
              const itemClassNames = [];
              if (selectedItem && itemToString(selectedItem) === itemToString(item)) {
                itemClassNames.push('active');
              }
              if (onSelectItem) {
                itemClassNames.push('selectable');
              }
              return (
                <ListGroupItem
                  key={itemToString(item)}
                  className={`mb-3 ${itemClassNames?.length ? itemClassNames.join(' ') : undefined}`}
                  onClick={() => {
                    if (onSelectItem) {
                      setSelectedItem(item);
                      onSelectItem(item);
                    }
                  }}
                >
                  {renderer ? renderer(item) : String(item)}
                  { removeEnabled ? (
                    <button aria-label="Clear" className="remove" type="button" onClick={() => removeItem(item)}>
                      <Icon name="trash-2" size={18} />
                      <span className="visually-hidden">Clear</span>
                    </button>
                  ) : null }
                </ListGroupItem>
              );
            })}
          </ListGroup>
        ) }
    </div>
  );
};

export default ItemsSelector;
