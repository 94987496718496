/* eslint-disable no-nested-ternary */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Badge,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { Icon } from '@ailibs/feather-react-ts';
import {
  ControlAction,
  IActionTarget,
  IControl,
  IControlDetails,
  IVulnerability,
  Severity,
  VulnerabilityStatus,
} from './Types';
import { useApi, useInvalidateQueries } from '../../query/GenericQuery';
import { asNameUpn, getCveUri, getStringDate } from '../../utils/StringUtils';
import ControlRemediateModal from './ControlRemediateModal';
import ControlUndoModal from './ControlUndoModal';
import { useTranslation } from '../../providers/TranslationProvider';
import { IRisk } from '../../types/RiskTypes';
import RenderHtml from '../../components/RenderHtml';
import {
  columnsToVisibilityState,
  TableStateV8,
  useTableStoreV8,
} from '../../common/table/TableStoreV8';
import VulnerabilityStatusBadge from './VulnerabilityStatusBadge';
import {
  useGetAssetNameAsText,
  useGetSeverityAsText,
  useVulnerabilityStatusAsText,
} from '../../utils/TranslationUtils';
import { IUser, Module } from '../../types/AccessTypes';
import { severityAsCssClassName } from '../vulnerabilities/Utils';
import { IJob, IPage, IVulnerabilityListOptions } from '../../types/Types';
import { startAutomationJobAndAwaitResult } from '../../utils/AutomationJobRunner';
import { useAccount } from '../../providers/AccountProvider';
import ROUTES from '../../routing/Routes';
import { useModules } from '../../providers/ModuleProvider';
import { TableFromPageable } from '../../common/table/TableFromPageable';
import { PagedResult } from '../../types/PagedResult';
import { PageableColumnDefV8, TableColumnDefV8 } from '../../common/table';
import {
  createPageableColumnHelper,
  IPagedTableFilter,
  ISorting,
  useAllVulnerabilityModuleOptions,
} from '../../common/table/PagedResultFilter';
import { TableFromArray } from '../../common/table/TableFromArray';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import { vulnerabilitiesTableId } from './VulnerabilitiesPage';
import { getSecurityAdvisoryUrl } from './SoftwareTable';

interface IAction {
  name: ControlAction;
  action: () => void;
}

interface IActionContext {
  vulnerability: IVulnerability;
  timestamp?: Date;
  name: ControlAction;
}

const startAnalyzis = async (
  vuln: IVulnerability,
  controlDetails: IControlDetails,
) => (
  await axios.post<IJob>(
      `/api/v1/module/vulnerabilityJobs/controlStart/${
        vuln.sourceModuleId
      }/execute-control-action/${vuln.asset.friendlyId}/${
        controlDetails?.frameworkFriendlyId
      }/${controlDetails?.friendlyId}/${
        vuln.uniqueId ? `${vuln.uniqueId}/` : ''
      }analyze`,
  )
).data;

const VulnerabilityActionDropdown = (params: {
  vuln: IVulnerability;
  actions: IAction[];
  active: string | undefined;
}) => {
  const { actions, vuln, active } = params;
  const i18n = useTranslation();

  return !actions.length ? null : (
    <DropdownButton
      id={`actions-${vuln.id}`}
      drop="down-centered"
      align="end"
      title="Actions"
      onClick={(ev) => {
        ev.stopPropagation();
      }}
    >
      {actions.map((a) => (
        <Dropdown.Item
          key={a.name}
          disabled={a.name === active}
          onClick={(ev) => {
            ev.stopPropagation();
            a.action();
          }}
        >
          {i18n.getString(`vulnerability.action.${a.name}`)}
          {a.name === active ? (
            <Spinner animation="grow" size="sm" className="ms-2" />
          ) : null}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

const getVulnerabilityActions = (
  actionTargets: IActionTarget[],
  vuln: IVulnerability,
  control: IControlDetails,
  setActiveContext: (context: IActionContext | undefined) => void,
  invalidateQueries: () => void,
  signal: AbortSignal | undefined,
) => {
  const actions = [] as IAction[];

  // TODO: limited to m365 for now, open after we've tested with another module
  if (vuln.sourceModuleId !== Module.m365) {
    return actions;
  }

  const supportAction = (action: ControlAction) => !!actionTargets
    && !!actionTargets.find(
      (a) => a.action === action && a.uniqueId === vuln.uniqueId,
    );

  if (
    supportAction('impact')
    && supportAction('configure')
    && vuln.status === VulnerabilityStatus.Open
  ) {
    actions.push({
      name: 'configure',
      action: () => {
        setActiveContext({ name: 'configure', vulnerability: vuln });
      },
    });
  }

  if (supportAction('revert')) {
    actions.push({
      name: 'revert',
      action: async () => {
        const { data: timestamp } = await axios.get<Date>(
          `/api/v1/module/vulnerabilityJobs/revertTimestamp/${
            vuln.asset.friendlyId
          }/${control.frameworkFriendlyId}/${control.friendlyId}${
            vuln.uniqueId ? `/${vuln.uniqueId}` : ''
          }`,
        );

        if (!timestamp) {
          toast.warn(
            'Control has no undo configuration and changes cannot be reverted',
          );
          return;
        }

        setActiveContext({ name: 'revert', vulnerability: vuln, timestamp });
      },
    });
  }

  if (supportAction('analyze')) {
    actions.push({
      name: 'analyze',
      action: async () => {
        setActiveContext({ name: 'analyze', vulnerability: vuln });
        try {
          toast.info('Started update', {
            toastId: 'analysis-status',
          });
          try {
            await startAutomationJobAndAwaitResult(
              () => startAnalyzis(vuln, control),
              signal,
            );
            toast.success('Vulnerability analysis has been updated', {
              toastId: 'analysis-status',
              updateId: 'analysis-status',
            });
            invalidateQueries();
          } finally {
            setActiveContext(undefined);
          }
        } catch {
          // Ignore
        }
      },
    });
  }

  return actions as IAction[];
};

const getRiskUri = (risk: IRisk) => `/risk/${encodeURIComponent(risk.riskId)}`;
const getControlUri = (
  control: IControl,
) => `/control/${encodeURIComponent(control.id)}`;

export interface IVulnerabilityColumns {
  id?: string;
  status?: boolean;
  created?: boolean;
  updated?: boolean;
  asset?: boolean;
  details?: boolean;
  summary?: boolean;
  risk?: boolean;
  control?: boolean;
  actions?: boolean;
  uniqueId?: boolean;
  severity?: boolean;
  relativeSeverity?: boolean;
  probability?: boolean;
  impact?: boolean;
  moduleId?: boolean;
  assignedTo?: boolean;
  cveIds?: boolean;
  securityAdvisoryIds?: boolean;
}

const allSeverities = [
  Severity.VeryLow,
  Severity.Low,
  Severity.Medium,
  Severity.High,
  Severity.VeryHigh,
];

const allStatuses = [
  VulnerabilityStatus.Unknown,
  VulnerabilityStatus.Mitigated,
  VulnerabilityStatus.Open,
];

/**
 * A generic component to display a list of vulnerabilities with an optional vulnerability action menu.
 * @param params:IProps
 * @returns The vulnerability list component
 */
export const VulnerabilitiesTable = ({
  id,
  pagedResult,
  pagedTableFilter,
  queryOptions,
  RowSubMenu,
  hide,
  show,
  isPaged,
  emptyText,
  disableFilters,
  disableColumnSelect,
  disablePagination,
  isLoading,
}: {
  id: string;
  pagedResult: PagedResult<IVulnerability>;
  setPage?: (page: IPage) => void;
  setFilters?: (filters: IVulnerabilityListOptions) => void;
  resetFilters?: () => void;
  isFiltered?: boolean;
  isPaged: boolean;
  queryOptions?: IVulnerabilityListOptions;
  RowSubMenu?: React.FunctionComponent<{ vuln: IVulnerability }>;
  hide?: IVulnerabilityColumns;
  show?: IVulnerabilityColumns;
  emptyText?: string | ReactElement;
  disableFilters?: boolean;
  disableColumnSelect?: boolean;
  sorting?: ISorting[];
  setSorting?: (sorting: ISorting[]) => void;
  onInitialized?: (state: TableStateV8) => void;
  skipFilterFromSearchParamInitialization?: boolean;
  disablePagination?: boolean;
  pagedTableFilter?: IPagedTableFilter<IVulnerabilityListOptions>;
  isLoading?: boolean;
}) => {
  const { hasModuleRole } = useAccount();
  const navigate = useNavigate();

  const {
    pageableQuery,
    setPage: pagedSetPage,
    reset: pagedReset,
    setSorting: pagedSetSorting,
    sorting: pagedSorting,
    appendQuery: pagedAppendQuery,
    isFiltered: pagedIsFiltered,
  } = pagedTableFilter ?? {};

  const isEnabled = useCallback(
    (
      columnName:
        | 'id'
        | 'status'
        | 'created'
        | 'updated'
        | 'uniqueId'
        | 'asset'
        | 'details'
        | 'summary'
        | 'risk'
        | 'actions'
        | 'control'
        | 'severity'
        | 'relativeSeverity'
        | 'impact'
        | 'probability'
        | 'moduleId'
        | 'assignedTo'
        | 'cveIds'
        | 'securityAdvisoryIds',
    ) => typeof hide === 'undefined' || !hide[columnName],
    [hide],
  );

  const columnHelper = createPageableColumnHelper<IVulnerability & {securityAdvisoryIds?: string[]}>();

  const vulnerabilityStatusAsText = useVulnerabilityStatusAsText();
  const severityAsText = useGetSeverityAsText();
  const getAssetName = useGetAssetNameAsText();
  const { getModuleNameOrDefault } = useModules();
  const allModuleOptions = useAllVulnerabilityModuleOptions();

  const tableColumns = useMemo(() => {
    const c = [];

    if (isEnabled('status')) {
      c.push(
        columnHelper.accessor(
          'status',
          {
            header: 'Status',
            cell: ({ row }) => (
              <VulnerabilityStatusBadge vulnerability={row.original} />
            ),
            formatter: (value: VulnerabilityStatus) => vulnerabilityStatusAsText(value),
            meta: {
              className: 'vuln-status-col',
            },
          },
          {
            filterFn: (values: VulnerabilityStatus[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  status: values,
                });
              }
            },
            filterPropertyName: 'status',
            supportMultiSelect: true,
            selectOptions: allStatuses,
          },
        ),
      );
    }

    if (isEnabled('relativeSeverity')) {
      c.push(
        columnHelper.accessor(
          'relativeSeverity',
          {
            header: 'Criticality',
            cell: ({ getValue, row }) => {
              if (row.original.status === VulnerabilityStatus.Mitigated) {
                return null;
              }
              const value = getValue();
              return value && value !== Severity.Unknown ? (
                <Badge bg="none" className={severityAsCssClassName(value)}>
                  {severityAsText(value)}
                </Badge>
              ) : (
                ''
              );
            },
            formatter: (value: Severity) => (value ? severityAsText(value) : 'None'),
          },
          {
            filterPropertyName: 'relativeSeverity',
            filterFn: (values: Severity[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  relativeSeverity: values.length ? values : undefined,
                });
              }
            },
            sortPropertyName: 'relativeSeverity',
            supportMultiSelect: false,
            selectOptions: allSeverities,
          },
        ),
      );
    }

    if (isEnabled('severity')) {
      c.push(
        columnHelper.accessor(
          'severity',
          {
            header: 'Severity',
            cell: ({ getValue, row }) => {
              if (row.original.status === VulnerabilityStatus.Mitigated) {
                return null;
              }
              const value = getValue();
              return value && value !== Severity.Unknown ? (
                <Badge bg="none" className={severityAsCssClassName(value)}>
                  {severityAsText(value)}
                </Badge>
              ) : (
                ''
              );
            },
            formatter: (value: Severity) => (value ? severityAsText(value) : 'None'),
            defaultHidden: !show?.severity && true,
          },
          {
            filterPropertyName: 'severity',
            filterFn: (values: Severity[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  severity: values.length ? values[0] : undefined,
                });
              }
            },
            sortPropertyName: 'severity',
            supportMultiSelect: false,
            selectOptions: allSeverities,
          },
        ),
      );
    }

    if (isEnabled('asset')) {
      c.push(
        columnHelper.accessor(
          'asset',
          {
            header: 'Asset',
            cell: ({ getValue }) => (
              <ClipboardCopy>{getAssetName(getValue())}</ClipboardCopy>
            ),
          },
          {
            filterPropertyName: 'asset',
            sortPropertyName: 'asset.name',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  asset: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('id')) {
      c.push(
        columnHelper.accessor(
          'id',
          {
            header: 'Id',
            defaultHidden: !show?.id && true,
            cell: ({ getValue }) => <ClipboardCopy>{getValue()}</ClipboardCopy>,
          },
          {
            filterPropertyName: 'id',
            filterFn: (values: number[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  id: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('uniqueId')) {
      c.push(
        columnHelper.accessor(
          'uniqueId',
          {
            header: 'Unique ID',
            defaultHidden: !show?.uniqueId && true,
            cell: ({ getValue }) => <ClipboardCopy>{getValue()}</ClipboardCopy>,
          },
          {
            filterPropertyName: 'uniqueId',
            sortPropertyName: 'uniqueId',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  uniqueId: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('summary')) {
      c.push(
        columnHelper.accessor(
          'summary',
          {
            header: 'Summary',
            cell: ({ getValue }) => <RenderHtml>{getValue()}</RenderHtml>,
          },
          {
            filterPropertyName: 'summary',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  summary: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('risk')) {
      c.push(
        columnHelper.accessor(
          'risk',
          {
            header: 'Risk ID',
            cell: ({ getValue }) => {
              const risk = getValue();
              return risk && hasModuleRole(Module.risk, 'read') ? (
                <OverlayTrigger overlay={<Tooltip>Go to risk</Tooltip>}>
                  <Link
                    to={getRiskUri(risk)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {risk?.riskId}
                  </Link>
                </OverlayTrigger>
              ) : (
                risk?.riskId
              );
            },
            defaultHidden: !show?.risk && true,
          },
          {
            filterPropertyName: 'risk',
            sortPropertyName: 'risk.riskId',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  risk: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('control')) {
      c.push(
        columnHelper.accessor(
          'control',
          {
            header: 'Control ID',
            cell: ({ row }) => {
              const vuln = row.original;
              return vuln.control
                && hasModuleRole(Module.vulnerability, 'read') ? (
                  <OverlayTrigger overlay={<Tooltip>Go to control</Tooltip>}>
                    <Link
                      to={getControlUri(vuln.control)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {vuln.control?.friendlyId}
                    </Link>
                  </OverlayTrigger>
                ) : (
                  vuln.control?.friendlyId
                );
            },
            defaultHidden: !show?.control && true,
          },
          {
            filterPropertyName: 'control',
            sortPropertyName: 'control.friendlyId',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  control: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('created')) {
      c.push(
        columnHelper.accessor(
          'created',
          {
            header: 'Created',
            cell: ({ row }) => {
              const vuln = row.original;
              return getStringDate(vuln.created);
            },
            defaultHidden: !show?.created && true,
            enableColumnFilter: false,
          },
          {
            filterPropertyName: 'createdAfter',
            filterFormatter: (values:string[]) => `${getStringDate(values[0])}\u00A0↦`,
          },
        ),
      );
    }

    if (isEnabled('updated')) {
      c.push(
        columnHelper.accessor(
          'updated',
          {
            header: 'Updated',
            cell: ({ row }) => {
              const vuln = row.original;
              return getStringDate(vuln.updated ?? vuln.created);
            },
            defaultHidden: !show?.updated && true,
            enableColumnFilter: false,
          },
          {
            filterPropertyName: 'updatedAfter',
            filterFormatter: (values:string[]) => `${getStringDate(values[0])}\u00A0↦`,
          },
        ),
      );
    }

    if (isEnabled('moduleId')) {
      c.push(
        columnHelper.accessor(
          'sourceModuleId',
          {
            header: 'Module',
            cell: ({ getValue }) => getModuleNameOrDefault(getValue()),
            formatter: getModuleNameOrDefault,
            defaultHidden: !show?.moduleId && true,
          },
          {
            filterPropertyName: 'sourceModuleIds',
            filterFn: (values: number[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  sourceModuleIds: values,
                });
              }
            },
            selectOptions: allModuleOptions,
            supportMultiSelect: true,
          },
        ),
      );
    }

    if (isEnabled('cveIds')) {
      c.push(
        columnHelper.accessor(
          'cveIds',
          {
            header: 'CVE',
            cell: ({ getValue }) => {
              const value = getValue();

              if (!value?.length) return null;

              return value
                ? value.map((cveId) => (
                  <Link key={cveId} to={getCveUri(cveId)} target="_blank">
                    {cveId}
                    <Icon name="external-link" className="ms-2" size="16" />
                  </Link>
                ))
                : null;
            },
            defaultHidden: !show?.cveIds && true,
          },
          {
            filterPropertyName: 'cveIds',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  cveIds: values.length ? values : undefined,
                });
              }
            },
          },
        ),
      );
    }

    // Only add securityAdvisoryIds if filtered. If filtered, we know the
    // vulnerability has the advisory and add that from the filter. We do not
    // expand this server side, due to the database cost.
    if (
      isEnabled('securityAdvisoryIds')
      && pagedTableFilter?.pageableQuery.securityAdvisoryIds?.length
    ) {
      c.push(
        columnHelper.accessor(
          'securityAdvisoryIds',
          {
            header: 'Security Advisory',
            cell: () => {
              const advisoryIds = queryOptions?.securityAdvisoryIds;
              if (!advisoryIds?.length) return null;

              return advisoryIds
                ? advisoryIds.map((advisoryId) => {
                  const externalUri = getSecurityAdvisoryUrl(advisoryId);

                  return externalUri ? (
                    <Link key={advisoryId} target="_blank" to={externalUri}>
                      {advisoryId}
                      <Icon name="external-link" size="16" className="ms-1" />
                    </Link>
                  ) : (
                    <ClipboardCopy key={advisoryId}>
                      {advisoryId}
                    </ClipboardCopy>
                  );
                })
                : null;
            },
            defaultHidden: !show?.securityAdvisoryIds && true,
          },
          {
            filterPropertyName: 'securityAdvisoryIds',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  securityAdvisoryIds: values.length ? values : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('assignedTo')) {
      c.push(
        columnHelper.accessor(
          'assignedTo',
          {
            header: 'Assignee',
            cell: ({ getValue }) => {
              const value = getValue();
              return value ? (
                <ClipboardCopy>{asNameUpn(value)}</ClipboardCopy>
              ) : (
                ''
              );
            },
            formatter: (user: IUser) => asNameUpn(user),
            defaultHidden: !show?.assignedTo && true,
          },
          {
            filterPropertyName: 'assignedTo',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  assignedTo: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (RowSubMenu) {
      c.push(
        columnHelper.display({
          id: 'submenu',
          header: 'Actions',
          cell: ({ row }) => <RowSubMenu vuln={row.original} />,
          enableColumnFilter: false,
        }),
      );
    }

    return c;
  }, [
    isEnabled,
    pagedTableFilter?.pageableQuery.securityAdvisoryIds?.length,
    RowSubMenu,
    columnHelper,
    vulnerabilityStatusAsText,
    pagedAppendQuery,
    severityAsText,
    show?.severity,
    show?.id,
    show?.uniqueId,
    show?.risk,
    show?.control,
    show?.created,
    show?.updated,
    show?.moduleId,
    show?.cveIds,
    show?.securityAdvisoryIds,
    show?.assignedTo,
    getAssetName,
    hasModuleRole,
    getModuleNameOrDefault,
    allModuleOptions,
    queryOptions?.securityAdvisoryIds,
  ]);

  const { store: tableStore } = useTableStoreV8(id, {
    visibilityState: columnsToVisibilityState(tableColumns),
  });
  const tableState = useStoreWithEqualityFn(tableStore);

  if (pagedResult.items.length === 0 && emptyText) {
    return emptyText;
  }

  return isPaged ? (
    <TableFromPageable
      id={id}
      pagedResult={pagedResult}
      setPage={(page) => {
        if (pagedSetPage != null) pagedSetPage(page);
      }}
      pageSize={pagedTableFilter?.pageableQuery.pageSize ?? -1}
      resetFilters={() => {
        if (pagedReset) pagedReset();
      }}
      filterValues={pageableQuery ?? queryOptions ?? {}}
      isFiltered={pagedIsFiltered ?? false}
      className="vulnerabilities-table"
      state={tableState}
      isLoading={isLoading}
      columnDefs={
        tableColumns as PageableColumnDefV8<IVulnerability, unknown>[]
      }
      disablePagination={disablePagination}
      hover={false}
      disableFilters={disableFilters}
      disableColumnSelect={disableColumnSelect}
      sorting={pagedSorting}
      setSorting={(newSorting) => {
        if (pagedSetSorting) pagedSetSorting(newSorting);
      }}
      /* defaultSorting={sort ?? defaultSorting} */
      alternatingRowColors
      onRowClick={(_, vuln) => {
        navigate(`${ROUTES.vulnerability.uri}/${vuln.id}`);
      }}
    />
  ) : (
    <TableFromArray
      data={pagedResult.items}
      className="vulnerabilities-table"
      state={tableState}
      columnDefs={tableColumns as TableColumnDefV8<IVulnerability, unknown>[]}
      disablePagination={disablePagination}
      hover={false}
      disableFilters={disableFilters}
      disableColumnSelect={disableColumnSelect}
      /* defaultSorting={sort ?? defaultSorting} */
      alternatingRowColors
      onRowClick={(_, vuln) => {
        navigate(`${ROUTES.vulnerability.uri}/${vuln.id}`);
      }}
    />
  );
};

interface IControlVulnerabilitiesTableProps {
  control: IControlDetails;
  pagedVulnerabilities: PagedResult<IVulnerability>;
  pagedTableFilter: IPagedTableFilter<IVulnerabilityListOptions>;
}

/**
 * A vulnerability tailored for use to display vulnerabilities associated with a control.
 * @param props:IControlDetails
 * @returns The control vulnerability table
 */
export const ControlVulnerabilitiesTable = (
  props: IControlVulnerabilitiesTableProps,
) => {
  const { control, pagedTableFilter, pagedVulnerabilities } = props;
  const { data: allModulesControlActions } = useApi<
    Record<number, IActionTarget[]>
  >(
    control
      && `module/vulnerabilityJobs/controlActions/${encodeURIComponent(
        control.friendlyId,
      )}`,
  );

  // TODO: Support control actions from any module
  // TODO: How do we link asset to module to determine what actions to support?
  const controlActions: IActionTarget[] = allModulesControlActions && allModulesControlActions[Module.m365]
    ? allModulesControlActions[Module.m365]
    : [];

  const abortSignalRef = useRef<AbortController>();

  useEffect(() => {
    abortSignalRef.current = new AbortController();
    return () => {
      abortSignalRef.current?.abort();
    };
  }, []);

  const [activeActionContext, setActiveActionContext] = useState<IActionContext>();

  const invalidateVulnerabilities = useInvalidateQueries('vulnerabilities');

  const invalidateQueries = () => {
    invalidateVulnerabilities();
  };

  const closeModal = () => {
    setActiveActionContext(undefined);
    invalidateQueries();
  };

  return !pagedVulnerabilities ? (
    <Spinner animation="border" />
  ) : (
    <VulnerabilitiesTable
      isPaged
      id={vulnerabilitiesTableId}
      pagedResult={pagedVulnerabilities}
      pagedTableFilter={pagedTableFilter}
      /* Noop setPage as this component only supports client side paging */
      setPage={() => {}}
      emptyText="Control has no associated vulnerabilities."
      RowSubMenu={({ vuln }) => (
        <>
          <VulnerabilityActionDropdown
            vuln={vuln}
            actions={getVulnerabilityActions(
              controlActions,
              vuln,
              control,
              setActiveActionContext,
              invalidateQueries,
              abortSignalRef.current?.signal,
            )}
            active={activeActionContext?.name}
          />
          <ControlRemediateModal
            control={control}
            vulerability={vuln}
            handleClose={closeModal}
            start
            show={
              activeActionContext?.name === 'configure'
              && activeActionContext?.vulnerability.id === vuln.id
            }
          />
          <ControlUndoModal
            control={control}
            vulerability={vuln}
            handleClose={closeModal}
            show={
              activeActionContext?.name === 'revert'
              && activeActionContext?.vulnerability.id === vuln.id
            }
            undoTimestamp={activeActionContext?.timestamp}
          />
        </>
      )}
      hide={{ control: true }}
    />
  );
};
