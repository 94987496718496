import React, {
  createContext, useContext, useRef,
} from 'react';
import { createStore, useStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { useAccount } from '../../providers/AccountProvider';
import { DashboardTile, useDashboardTiles } from '../../pages';
import { sortByTilePosition } from './DashboardUtil';
import { ICompany } from '../../types/AccessTypes';

interface IDashboardState {
  tileIds: number[],
  updateTileIds: (ids: number[]) => void,
  maxTileId: number
}

/**
 * Add tiles that cannot be hidden, and new non-default hidden tiles since last persistance.
 */
const ensureRequiredTiles = (maxPersistedTileId:number, dashboardTiles:DashboardTile[], tileIds:number[]) => {
  const requiredTileIds = dashboardTiles
    .filter((t) => t.disableHide)
    .map((t) => t.id);

  requiredTileIds.forEach((id) => {
    if (!tileIds.includes(id)) {
      // Push required tiles to the top
      tileIds.unshift(id);
    }
  });

  // Find new tiles since last persist
  const newTileIds = dashboardTiles
    .filter((t) => !t.defaultHidden && t.id > maxPersistedTileId)
    .map((t) => t.id);

  // Add tiles in order they are defined in dashboardTiles
  const orderedTileIds = dashboardTiles.map((t) => t.id);

  orderedTileIds.forEach((id) => {
    if (newTileIds.includes(id) && !tileIds.includes(id)) {
      tileIds.push(id);
    }
  });

  const topTiles = dashboardTiles
    .filter((t) => t.fixedPosition === 'top')
    .map((t) => t.id);

  const bottomTiles = dashboardTiles
    .filter((t) => t.fixedPosition === 'bottom')
    .map((t) => t.id);

  // Sort top and bottom tiles to top and bottom
  tileIds.sort((a, b) => {
    if (topTiles.includes(a)) {
      return topTiles.includes(b) ? 0 : -1;
    }
    if (bottomTiles.includes(a)) {
      return bottomTiles.includes(b) ? 0 : 1;
    }
    return 0;
  });

  return tileIds;
};

const createDashboardStore = (customer:ICompany, dashboardTiles:DashboardTile[]) => createStore<IDashboardState>()(
  persist(
    (set) => {
      const maxTileId = Math.max(...dashboardTiles.map((t) => t.id));
      return {
        maxTileId,
        tileIds: dashboardTiles.filter((t) => !t.defaultHidden).map((t) => t.id),
        updateTileIds: (ids: number[]) => {
          set((store) => ({
            ...store,
            tileIds: ensureRequiredTiles(maxTileId, dashboardTiles, ids),
          }));
        },
      };
    },
    {
      name: `${customer ? customer.id : 'no-customer'}-dashboard-tileIds`,
      merge: (persistedState, currentState) => {
        const persistedStore = persistedState as IDashboardState;

        // Make sure always visible tiles are present
        const tileMap:Record<number, DashboardTile> = {};
        dashboardTiles.forEach((t) => { tileMap[t.id] = t; });
        const tileIds = ensureRequiredTiles(persistedStore.maxTileId, dashboardTiles, [...persistedStore.tileIds]);
        // Fix tile position
        tileIds.sort((idA, idB) => (
          sortByTilePosition(tileMap[idA].fixedPosition, tileMap[idB].fixedPosition)
        ));

        return {
          ...currentState,
          tileIds,
        };
      },
    },
  ),
);

type DashboardStore = ReturnType<typeof createDashboardStore>;

const DashboardContext = createContext<DashboardStore | null>(null);

export const DashboardContextProvider = ({ children }: { children: React.ReactNode }) => {
  const storeRef = useRef<DashboardStore>();

  const { customer } = useAccount();
  const dashboardTiles = useDashboardTiles();

  if (!storeRef.current) {
    storeRef.current = createDashboardStore(customer, dashboardTiles);
  }

  return (
    <DashboardContext.Provider value={storeRef.current}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardStore = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('DashboardContext is not available');
  }
  return useStore(context);
};
