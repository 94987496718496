import { createColumnHelper } from '@tanstack/react-table';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Button, Card, Col, OverlayTrigger, Row, Stack, Tooltip,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { Link, useLoaderData } from 'react-router-dom';
import axios from 'axios';
import { QueryClient } from '@tanstack/react-query';
import { useStore } from 'zustand';
import { TableCellDateFormattedV8, TableColumnDefV8 } from '../../common/table';
import { columnsToVisibilityState, useTableStoreV8 } from '../../common/table/TableStoreV8';
import { copyClipboard } from '../../utils/Utils';
import AssessmentUploadModal from './AssessmentUploadModal';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { useConfigContext } from '../../contexts/ConfigContext';
import useModalContext from '../../contexts/ModalContext';
import { urlEncodeBase64 } from '../../utils/StringUtils';
import ROUTES from '../../routing/Routes';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { TableFromArray } from '../../common/table/TableFromArray';
import { dateStringFilterFn } from '../../common/table/filters/DateStringFilterV8';
import { useDownloadFromApi } from '../../common/useDownloadFromApi';

enum FileType {
  'unknown',
  'assessmentScript'
}

interface IFile {
  sha512: string,
  name: string,
  created: Date
  updated: Date
  fileType: FileType
}

interface IData {
  files:IFile[]
}

export const AdminAssessmentsPage:IComponentWithLoader<unknown, IData> = {
  loader: async (queryClient:QueryClient) => {
    const files = await getOrFetchFromApi<PagedResult<IFile>>(
      queryClient,
      'files',
      {
        fileTypes: [1],
      },
    );

    return {
      files: files.items,
    };
  },
  Component: () => {
    const [show, setShow] = useState(false);
    const { customer, hasModuleRole } = useAccount();

    const { files: loaderFiles } = useLoaderData() as Awaited<IData>;

    const { data: queryFiles, invalidate: invalidateFiles } = useApiLoaderData<PagedResult<IFile>, PagedResult<IFile>>(
      'files',
      (loaderData) => loaderData,
      {
        fileTypes: [1],
      },
    );

    // Loader should have prefetch the query before activating the component. Use files from
    // loader in case query for some reason returns something else. We need to use files from
    // query to update on upload/delete.
    const files = queryFiles?.items ?? loaderFiles;

    const fileNameMap = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
      const _fileNameMap = {} as Record<string, IFile>;
      if (files) {
        files.forEach((f) => { _fileNameMap[f.sha512] = f; });
      }
      return _fileNameMap;
    }, [files]);

    const { openConfirmLegacy, openModal } = useModalContext();
    const download = useDownloadFromApi();

    const deleteFile = useCallback(async (sha512:string) => {
      openConfirmLegacy(
        async (confirmed) => {
          if (!confirmed) return;
          try {
            await axios.delete(`/api/v1/module/admin/assessments/${urlEncodeBase64(sha512)}`);
            invalidateFiles();
          } catch {
          // Ignore
          }
        },
        'Delete assessment?',
        <div>
          Please confirm that you would like to delete the assessment.
          <br />
          <br />
          Deleting the assessment will prevent any ongoing assessment using this manuscript to be aborted.
        </div>,
        'Yes, delete it!',
        'Don\'t delete it',
      );
    }, [invalidateFiles, openConfirmLegacy]);

    const openFile = useCallback(async (fileName:string, sha512:string) => {
      const fileContent = await axios.get(`/api/v1/files/${urlEncodeBase64(sha512)}`);
      await openModal({
        title: fileName,
        content: (
          <pre>{fileContent.data}</pre>
        ),
        closeText: 'Close',
        size: 'lg',
      });
    }, [openModal]);

    const columnHelper = createColumnHelper<IFile>();

    const config = useConfigContext();

    const columnDefs = useMemo<TableColumnDefV8<IFile, unknown>[]>(
      () => [
        columnHelper.accessor('name', {
          header: 'Name',
          meta: {
            className: 'w-100',
          },
        }),
        columnHelper.accessor('created', {
          header: 'Created',
          cell: ({ getValue }) => TableCellDateFormattedV8(getValue()),
          filterFn: dateStringFilterFn,
          meta: {
            className: 'text-nowrap',
          },
        }),
        columnHelper.accessor('sha512', {
          id: 'actions',
          header: 'Actions',
          enableColumnFilter: false,
          cell: ({ getValue }) => {
            const assessmentRelativeUrl = `${ROUTES.assessment.uri}/${urlEncodeBase64(getValue())}`;
            const assessmentAbsoluteUrl = `${config.AZURE_REDIRECT_URI.replace(/\/$/, '')}${assessmentRelativeUrl}`;
            const file = fileNameMap[getValue()];
            return (
              <div className="justify-content-end text-nowrap">
                <OverlayTrigger
                  overlay={<Tooltip>Delete assessment</Tooltip>}
                >
                  <Button
                    variant="link"
                    onClick={() => deleteFile(getValue())}
                  >
                    <Icon size="18" name="trash" />
                  </Button>
                </OverlayTrigger>
                { hasModuleRole(Module.admin, 'read')
                  ? (
                    <OverlayTrigger
                      overlay={<Tooltip>Show assessment file</Tooltip>}
                    >
                      <Button
                        variant="link"
                        className="ms-2"
                        onClick={() => openFile(file?.name, getValue())}
                      >
                        <Icon size="18" name="eye" />
                      </Button>
                    </OverlayTrigger>
                  )
                  : null }
                { hasModuleRole(Module.admin, 'read')
                  ? (
                    <OverlayTrigger
                      overlay={<Tooltip>Download assessment file</Tooltip>}
                    >
                      <Button
                        variant="link"
                        className="ms-2"
                        onClick={() => download(`files/${urlEncodeBase64(file.sha512)}`, `${file?.name}.yaml`)}
                      >
                        <Icon size="18" name="download" />
                      </Button>
                    </OverlayTrigger>
                  ) : null }
                <OverlayTrigger
                  overlay={<Tooltip>Copy link to assessment</Tooltip>}
                >
                  <Button
                    variant="link"
                    size="sm"
                    className="ms-2"
                    onClick={() => copyClipboard(assessmentAbsoluteUrl, 'Assessment URL copied to clipboard')}
                  >
                    <Icon size="18" name="copy" />
                  </Button>
                </OverlayTrigger>
                { hasModuleRole(Module.assessment, 'readWrite')
                  ? (
                    <OverlayTrigger
                      overlay={(
                        <Tooltip>
                          Use script to assess
                          {' '}
                          {customer?.name}
                        </Tooltip>
                      )}
                    >
                      <Link
                        to={assessmentRelativeUrl}
                        className="ms-2 btn btn-link"
                      >
                        <Icon size="18" name="arrow-right-circle" />
                      </Link>
                    </OverlayTrigger>
                  ) : null }
              </div>
            );
          },
          meta: {
            className: 'text-end ps-3',
          },
        }),
      ] as TableColumnDefV8<IFile, unknown>[],
      [columnHelper, deleteFile, config, customer, openFile, hasModuleRole, fileNameMap, download],
    );

    const { store: tableStore } = useTableStoreV8(
      'adminAssessments',
      {
        visibilityState: columnsToVisibilityState(columnDefs),
      },
    );

    const tableState = useStore(tableStore);

    return (
      <>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>
                Assessment manuscripts
              </Card.Header>
              <Card.Body className="overflow-auto">
                <Row>
                  <Col md={12} className="mb-3">
                    <TableFromArray
                      columnDefs={columnDefs}
                      data={files}
                      state={tableState}
                      disableColumnSelect
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Stack direction="horizontal" gap={2}>
                      <Button
                        onClick={() => setShow(true)}
                      >
                        Upload assessment
                      </Button>
                    </Stack>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <AssessmentUploadModal
          show={show}
          close={() => setShow(false)}
        />
      </>
    );
  },
};
